<template>
  <v-container style="height: 100%;">
    <v-row :justify="loading ? 'center' : 'start'" :align="loading ? 'center' : 'start'" style="min-height: 100%;">
      <v-progress-circular
        v-if="loading"
        :size="40"
        :width="3"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <v-col
        v-else-if="election"
        cols="12"
      >

        <h2 class="headline font-weight-bold mb-5">
          {{ election.title }} ({{ startDate }} - {{ endDate }})
        </h2>

        <v-stepper v-model="step" v-if="!voted">
          <v-stepper-header>
            <v-stepper-step
              :complete="step > 1"
              step="1"
            >
              Choose candidates
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="step > 2"
              step="2"
            >
              Confirm candidates
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">
              Submit your votes
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row align-sm="stretch" v-for="position in positions" :key="position.id" class="mb-6">
                <v-col cols="12" class="pb-0">
                  <h3 class="text-h6">
                    {{ position.title }} candidates <span v-if="position.posts > 1">({{ position.posts }} posts)</span>
                  </h3>
                </v-col>
                <ElectionCandidate
                  v-for="candidate in position.candidates"
                  :key="candidate.id"
                  :position="position"
                  :candidate="candidate"
                  :selected="selectedCandidates"
                  @choose="chooseCandidate"
                />
              </v-row>
              <v-btn
                class="mb-2"
                color="primary"
                :disabled="!enableStep1Btn"
                @click="step = 2"
              >
                Continue
              </v-btn>
              <v-btn text to="/" class="mb-2">Cancel</v-btn>
            </v-stepper-content>
            <v-stepper-content step="2">
              <h2 class="text-h6 mb-4">Preview & confirm selected candidates</h2>
              <v-row align-sm="stretch" v-for="position in positions" :key="position.id" class="mb-6">
                <v-col cols="12" class="pb-0">
                  <h3 class="text-h6">
                    {{ position.title }} candidates <span v-if="position.posts > 1">({{ position.posts }} posts)</span>
                  </h3>
                </v-col>
                <ElectionCandidate
                  v-for="candidate in position.candidates"
                  :key="candidate.id"
                  :position="position"
                  :candidate="candidate"
                  :selected="selectedCandidates"
                  is-preview
                />
              </v-row>
              <v-btn
                class="mb-2"
                color="primary"
                :loading="otpLoader"
                :disabled="otpLoader"
                @click="sendOtp"
              >
                Confirm Selection
              </v-btn>
              <v-btn text
                class="mb-2"
                :disabled="otpLoader"
                @click="step = 1"
              >
                Go Back
              </v-btn>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-row v-if="finished">
                <v-col cols="12">
                  <v-alert
                    type="success"
                  >You have successfully finished your voting.</v-alert>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12" class="pb-4">
                  <h2 class="text-h6 mb-4">Enter OTP & Submit your votes</h2>
                  <p class="caption">We sent a 4 digit OTP to your registered mobile number.</p>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="2">
                  <v-text-field
                    v-model="otp"
                    :rules="[rules.required, rules.otp]"
                    label="OTP"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-btn
                    color="primary"
                    :loading="votingLoader"
                    :disabled="votingLoader"
                    @click="submitVotes"
                  >
                    Submit Votes
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <v-container v-else>
          <v-alert
            type="success"
          >You already finished your voting.</v-alert>
          <v-btn color="primary" to="/" class="mb-2">Go back</v-btn>

          <v-row align-sm="stretch" v-for="position in positions" :key="position.id" class="mb-6">
            <v-col cols="12" class="pb-0">
              <h3 class="text-h6">
                {{ position.title }} candidates <span v-if="position.posts > 1">({{ position.posts }} posts)</span>
              </h3>
            </v-col>
            <ElectionCandidate
              v-for="candidate in position.candidates"
              :key="candidate.id"
              :position="position"
              :candidate="candidate"
              :selected="selectedCandidates"
              is-preview
            />
          </v-row>
        </v-container>
      </v-col>
      <v-col
        v-else
        cols="12"
      >
        <span>Couldn't find election.</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import * as moment from 'moment'
  import ElectionCandidate from '../components/ElectionCandidate'

  export default {
    name: 'ElectionDetail',

    components: {
      ElectionCandidate
    },

    data: () => ({
      step: 1,
      finished: false,
      loading: false,
      election: null,
      voted: false,
      selectedCandidates: [],
      otpLoader: false,
      votingLoader: false,
      otp: null,
      rules: {
        required: value => !!value || 'OTP is required.',
        otp: v => v && v.length === 4 || 'OTP should be 4 characters.',
      },
    }),

    computed: {
      positions() {
        return this.election ? this.election.positions.filter(({ candidates }) => candidates.length) : [];
      },
      startDate() {
        if (!this.election) {
          return null
        }
        return moment(this.election.start_time).format('DD/MM/YYYY')
      },
      endDate() {
        if (!this.election) {
          return null
        }
        return moment(this.election.end_time).format('DD/MM/YYYY')
      },
      enableStep1Btn () {
        if (!this.election) {
          return false
        }
        let posts = 0;
        this.positions.forEach(item => {
          posts += item.posts;
        });
        return this.selectedCandidates.length === posts;
      }
    },

    created () {
      this.getElection()
    },

    methods: {
      async getElection () {
        this.loading = true;

        const { success, data } = await this.$get(`elections/${this.$route.params.id}`)
        if (success) {
          this.election = data
        }

        const { success: votingSuccess, data: votingData } = await this.$get(`elections/${this.$route.params.id}/${window.user.id}`)
        if (votingSuccess) {
          this.voted = true;
          this.selectedCandidates = [];
          const votedCandidates = votingData.map(({ candidate }) => candidate);
          this.election.positions.forEach(position => {
            position.candidates.forEach(candidate => {
              if (votedCandidates.includes(candidate.id)) {
                this.selectedCandidates.push({ position, candidate });
              }
            });
          });
        }

        this.loading = false
      },
      chooseCandidate ({ position, candidate }) {
        let itemIndex = this.selectedCandidates.findIndex(item => item.candidate.id === candidate.id);
        if (itemIndex === -1) {
          let items = this.selectedCandidates.filter(item => item.position.id === position.id);
          if (items.length === position.posts) {
            let index = this.selectedCandidates.findIndex(item => item.position.id === position.id);
            this.selectedCandidates.splice(index, 1);
          }
          this.selectedCandidates.push({ position, candidate });
        }
      },
      async sendOtp () {
        this.otpLoader = true
        const { success } = await this.$post(`voting-otp`, { election: this.election.id, doctor: window.user.id })
        this.otpLoader = false
        if (success) {
          this.step = 3
        }
        else {
          alert('error sending otp')
        }
      },
      async submitVotes () {
        this.votingLoader = true
        const votes = this.selectedCandidates.map(({ position, candidate }) => {
          return {
            candidate: candidate.id,
            position: position.id
          }
        })
        const { success } = await this.$post(`submit-votes`, { doctor: window.user.id, election: this.election.id, otp: this.otp, votes })
        this.votingLoader = false
        if (success) {
          this.finished = true
        }
        else {
          alert('error submitting votes')
        }
      }
    }
  }
</script>
