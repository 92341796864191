<template>
	<v-col cols="12" sm="6" md="4" lg="3" v-if="isPreview ? isChosen : true">
		<v-card
			:class="{ 'chosen': isChosen }"
			outlined
			elevation="0"
			:color="isChosen ? 'light-green lighten-4' : 'white'"
			@click="onSelect"
		>
			<v-card-title>{{ candidate.title }}</v-card-title>
			<v-card-subtitle>{{ candidate.memno }}</v-card-subtitle>
			<v-card-text>
				<p>{{ candidate.notes }}</p>
			</v-card-text>
		</v-card>
	</v-col>
</template>

<script>
	export default {
		props: {
			candidate: {
				type: Object,
				required: true
			},
			position: {
				type: Object,
				required: true
			},
			selected: {
				type: Array,
				default: () => []
			},
			isPreview: {
				type: Boolean,
				default: false
			},
		},
		computed: {
			isChosen () {
				return this.selected.findIndex(({ candidate, position }) => candidate.id === this.candidate.id && position.id === this.position.id) !== -1
			}
		},
		methods: {
			onSelect() {
				if (!this.isPreview) {
					this.$emit('choose', { position: this.position, candidate: this.candidate });
				}
			}
		},
	}
</script>

<style scoped>
	.v-application .v-card.white {
		border-color: #e5e5e5!important;
	}
	.v-application .light-green.lighten-4.chosen {
		border-color: #aed581!important;
	}
</style>
